<template>
  <vue-select
    v-bind="$attrs"
    ref="select2"
    v-model="items"
    :settings="settings"
    class="vue-select2 bg-white"
    :name="name"
    :class="cssClass"
    @select="$emit('select', $event)"
    @change="$emit('change', $event)"
  >
  </vue-select>
</template>

<script>
/* global $ */
import Select2 from 'v-select2-component';

export default {
  name: 'SportType',
  components: {
    'vue-select': Select2,
  },
  props: {
    cssClass: { type: [String], required: false, default: null },
    initialValue: { type: [String, Number, Array], required: false, default: null },
    multiple: { type: Boolean, required: false, default: false },
    showAllActivities: { type: Boolean, required: false, default: false },
    placeholder: { type: String, required: false, default: 'Sports' },
    name: { type: String, required: false, default: null },
  },
  emits: ['input', 'select', 'change'],
  data() {
    return {
      items: this.initialValue,
      term: null,
    };
  },
  computed: {
    endpoint() {
      return '/api/sports';
    },
    settings() {
      return {
        theme: 'bootstrap',
        placeholder: this.placeholder,
        allowClear: true,
        multiple: this.multiple,
        ajax: {
          delay: 400,
          dataType: 'json',
          url: this.endpoint,
          processResults: this.processResults,
          data: (params) => {
            this.term = params.term;
            return {
              'descriptions.description': params.term,
            };
          },
        },
      };
    },
  },
  mounted() {
    if (this.initialValue && Array.isArray(this.initialValue) && this.initialValue.length) {
      this.initialValue.forEach((iv) => {
        this.$refs.select2.select2.append(
          $(`<option selected value="${iv.id}">${iv.text}</option>`)
        );
      });

      this.$refs.select2.select2.trigger('change');
    }
  },
  methods: {
    processResults(data) {
      const { preferredSports } = this.$store.state;
      let results = this.term
        ? []
        : preferredSports.filter(({ text }) => !this.term || text.includes(this.term));

      results = results.concat(
        data.map((i) => {
          const descForLang =
            i.descriptions.find((d) => d.language === Translator.locale) ?? i.descriptions[0];
          return { text: this.capitalize(descForLang.title), id: i.id };
        })
      );

      if (this.showAllActivities) {
        results = results.concat([
          {
            id: -1,
            text: this.trans('website.search.activities.show_all'),
          },
        ]);
      }

      return { results };
    },
  },
};
</script>
