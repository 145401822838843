export function capitalize(value) {
  if (!value) return '';
  const strValue = value.toString();
  return strValue.charAt(0).toUpperCase() + strValue.slice(1);
}

export const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
