import Vue from 'vue';
import { capitalize } from '../../helpers/typography';

Vue.mixin({
  methods: {
    capitalize(value) {
      return capitalize(value);
    },
  },
});
