/* eslint-disable no-underscore-dangle */
import SubmissionError from '../../error/SubmissionError';
import UnprocessableEntityError from '../../error/UnprocessableEntityError';

export default {
  methods: {
    toastError(e) {
      console.error(e);
      if (e instanceof UnprocessableEntityError) {
        // const errorKeys = Object.keys(e.errors);
        // if (errorKeys.length > 1) {
        // const errors = errorKeys.map((k) => this.trans(`error.${k.code}`));
        // this.toast(errors.join('\n'));
        // } else {
        // eslint-disable-next-line no-underscore-dangle
        this.toast(e.errors._error);
        // }
      } else if (e instanceof SubmissionError) {
        // console.dir(e);
        console.dir(e.errors);
        this.toast(e.errors?._error ?? e.message);
      } else {
        this.toast(e.message);
      }
    },
  },
};
